html {
  box-sizing: border-box;
  background: #eeeeee;
  font-weight: 200;
}

body {
  margin: 0;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
