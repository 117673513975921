.location {
  > h2 {
    margin-top: 1rem;
  }
  .location-heading {
    display: flex;
    justify-content: center;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > * {
        margin-left: 1rem;
      }
    }
  }
  #directions {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 1rem;

    .detailedDirections {
      white-space: break-spaces;
    }
  }

  #colorconvention {
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: center;
    flex-wrap: wrap;
    line-height: 2;
  }

  #colorconvention li {
    margin-right: 1rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  .colorlegend {
    color: var(--darkgrey);
  }

  div.transportList {
    font-size: 1rem;
    text-align: justify;
    margin: 2.5px;
    width: 100%;
    line-height: 2;
    padding: 10px 20px;

    h3 {
      display: flex;

      > * {
        margin-right: 1rem;
      }
    }
  }

  div.transportList i {
    margin-right: 0.5rem;
    color: var(--olivegreen);
  }

  #importantInformation {
    margin: 3rem auto;
    padding: 2rem;
    max-width: 775px;
    word-wrap: normal;
    border-radius: 5px;
    border: 1px solid var(--pink);
    box-shadow: 5px 5px var(--palepink);
  }

  @media only screen and (max-width: 600px) {
    .image_grid li.small {
      flex-basis: 50%;
    }

    .image_grid li.large {
      flex-basis: 50%;
    }

    #directions {
      flex-wrap: wrap;
    }
  }

  .map-locations-container {
    display: flex;
    flex-direction: column;

    > .location-site {
      padding: 1rem;
      width: 100%;
    }
  }

  .location-card {
    article {
      display: flex;
      flex-direction: column;
      align-items: center;

      > * {
        margin-bottom: 1rem;
      }
    }
  }

  @media only screen and (min-width: 800px) {
    .map-locations-container {
      display: flex;
      flex-direction: row;

      > .location-site {
        width: 50%;
      }
    }

    .slider-container {
      .slider {
        .slide-content {
          height: auto;
          img {
            width: 45vw;
          }
        }
      }
    }
  }
}
