.location-card {
  .flip-back {
    position: absolute;
    left: 0;
    z-index: 1;
    cursor: pointer;
  }
  &--front {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &--back {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.location-card {
  position: relative;
  background-color: #fff;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in-out;
  border-radius: 15px;
}

.location-card .content {
  position: relative;
  padding: 10px 15px;
  color: #111;
  text-align: center;

  transition: 0.3s ease-in-out;
}

@media only screen and (min-width: 1200px) {
  .location-card {
    max-width: 500px;
  }
}
