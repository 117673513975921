/* CSS */
.button {
  text-transform: capitalize;
  background-color: var(--olivegreen);
  border: 1px solid var(--olivegreen);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 1px;
  color: #ffffff;
  cursor: pointer;
  display: block;
  font-family: -apple-system, '.SFNSDisplay-Regular', 'Helvetica Neue',
    Helvetica, Arial, sans-serif;
  font-size: 17px;
  line-height: 100%;
  margin: 0;
  outline: 0;
  padding: 11px 15px 12px;
  text-align: center;
  transition: box-shadow 0.05s ease-in-out, opacity 0.05s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &--secondary {
    background-color: var(--white);
    color: var(--olivegreen);
  }
}

.button:hover {
  box-shadow: rgba(255, 255, 255, 0.3) 0 0 2px inset,
    rgba(0, 0, 0, 0.4) 0 1px 2px;
  text-decoration: none;
  transition-duration: 0.15s, 0.15s;
}

.button:active {
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 4px inset, rgba(0, 0, 0, 0.4) 0 1px 1px;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.button:disabled:active {
  pointer-events: none;
}

.button:disabled:hover {
  box-shadow: none;
}
