.section-divider {
  margin: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: center;

  > .divider-image {
    width: 200px;
    .cls-1 {
      fill: var(--secondary);
    }
  }
}
