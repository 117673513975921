.slider-container {
  //position: relative;
  //z-index: 0;
  .slider {
    .slide-content {
      padding: 1rem;

      img {
        display: block;
        width: 100vw;
        margin-bottom: 1rem;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      color: var(--secondary);
    }
  }
}
