.rsvp-wizard {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 1rem;
  > * {
    margin-bottom: 2rem;
  }

  > .content {
    position: relative;
    background-color: #fff;
    padding: 20px 15px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in-out;
    border-radius: 15px;
    max-width: 500px;
    //min-height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    > .logo {
      position: absolute;
      bottom: 0.5rem;
      right: 1rem;
      width: 45px;
    }

    .my-node-enter {
      opacity: 0;
    }
    .my-node-enter-active {
      opacity: 1;
      transition: opacity 200ms;
    }

    .button-bar {
      display: flex;
      > * {
        margin: 1rem;
      }
    }
  }
}
