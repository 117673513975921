.location-site {
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 1rem;
  }
  > .location-card {
    width: 100%;
    align-self: center;
  }
}
