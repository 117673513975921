.venue-info {
  display: flex;
  flex-direction: column;
  align-items: center;

  .location {
    font-size: 1.1rem;
    text-align: center;
  }

  .time {
    font-weight: bold;
  }

  > * {
    margin-bottom: 1rem;
  }
}
