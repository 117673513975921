.iban-card {
  min-width: 300px;

  background-color: #fff;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in-out;
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    border-radius: 100px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .iban {
    .iban-content {
      display: flex;
      align-items: flex-start;

      > * {
        margin-right: 0.5rem;
      }

      .copy-area {
        position: relative;
        > img.copy {
          cursor: pointer;
        }

        img.check {
          position: absolute;
          bottom: 10px;
          right: -4px;
        }
      }
    }
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    > label {
      margin-bottom: 0.2rem;
    }
  }
}

@media only screen and (min-width: 900px) {
  .iban-card {
    min-width: 400px;
  }
}
