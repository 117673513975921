.animation-container {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
}
.loginContainer {
  .error {
    padding: 0.5rem;
    margin-bottom: 1rem;
    color: var(--secondary);
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: repeat;
}

.loginContainer > .login-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 30rem;
  margin: 0.5rem 1rem;
  padding: 2rem 2.4rem;
  background-color: white;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: center;
}

.loginContainer > .login-form > #inputFields {
  margin-bottom: 0.5rem;
  > input {
    display: inline-block;
    width: 100%;
    line-height: 3.125rem;
    height: 3.125rem;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid var(--olivegreen);
    outline: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin: 0.5rem 0;
    text-align: center;
    font-family: 'Thasadith';
  }
}

.loginContainer > .login-form > p {
  text-align: center;
  margin: 1rem 0;
  line-height: 1.5rem;
  font-size: 1rem;
}

.flags {
  background-repeat: no-repeat;
  background-size: cover;
  height: 1.2rem;
  width: 2rem;
  margin: 0.5rem;
  outline: none;
  cursor: pointer;
}

#languages {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 0.5rem;
  left: 0;

  > * {
    cursor: pointer;
    width: 30px;
    margin: 0.5rem;
  }
}
