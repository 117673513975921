.rsvp-state {
  position: relative;
  min-height: 6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  > * {
    margin-bottom: 1rem;
  }

  .display-only {
    width: 100%;
    padding: 1rem;
    > span {
      font-size: 1.5rem;
      text-align: justify;
      line-height: 1.5;
    }
  }

  .text-value-answer {
    width: 100%;
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 1rem;
    }

    textarea {
      resize: none;
      padding: 0.5rem;
    }

    input {
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      box-sizing: border-box;
    }
  }

  .phone-number {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > * {
      margin-bottom: 1rem;
    }

    > .phone-number-input {
      display: flex;
      align-items: center;

      > * {
        margin-right: 1rem;
      }

      div[data-name='countrySelect'] {
        flex-grow: 1;
      }

      input[type='text'] {
        width: 100%;
        margin-right: 0;
        flex-grow: 2;
        height: 44px;
        box-sizing: border-box;
      }
    }
  }

  .will-make-it {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > span {
      margin-bottom: 1rem;
    }

    label {
      margin-bottom: 0.5rem;
      > input {
        margin-left: 0;
      }
    }
  }

  .people-coming {
    display: flex;
    flex-direction: column;
    width: 100%;
    > * {
      margin-bottom: 1rem;
    }
    ul {
      li {
        display: flex;
        > span {
          width: 50%;
          border: dashed 1pt var(--olivegreen);
          border-radius: 5px;
          padding: 0.5rem;
        }

        > img {
          cursor: pointer;
        }

        > * {
          margin: 0.5rem;
          margin-left: 0;
        }
      }
    }

    > div {
      display: flex;

      > input {
        width: 50%;
      }

      > * {
        margin-right: 1rem;
      }
    }
  }

  .submit {
    display: flex;
    flex-direction: column;
    align-items: center;
    > span {
      margin-bottom: 1.5rem;
      text-align: justify;
      line-height: 1.2;
    }

    svg {
      path[fill='rgb(93,104,248)'] {
        fill: var(--olivegreen);
      }
      path[fill='rgb(70,75,216)'] {
        fill: #6a765b;
      }

      path[fill='rgb(56,49,172)'] {
        fill: #626d54;
      }
    }
  }

  .thank-you {
    display: flex;
    flex-direction: column;

    > h3 {
      margin: 0 auto;
      margin-bottom: 1.5rem;
    }

    > * {
      margin-bottom: 0.5rem;
    }

    > span {
      text-align: justify;
    }
  }
}
