#aboutus {
  display: flex;
  flex-direction: column;
  align-items: center;
  #brideandgroom {
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    align-items: center;

    .column-divider {
      display: none;
    }
    #bride,
    #groom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 1rem;
      border-radius: 10px;

      > img {
        max-width: 250px;
        border-radius: 130px;
      }

      .contactsInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        .name {
          color: var(--secondary);
          text-align: center;
          font-size: 1.5rem;
          font-family: 'Calligraffiti';
        }
        > span {
          margin-bottom: 1rem;
          display: flex;
          align-items: center;

          > img {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  #aboutus {
    #brideandgroom {
      flex-direction: row;

      .column-divider {
        display: block;
      }
    }
  }
}
