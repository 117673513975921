div[data-name='countrySelect'] {
  .option {
    display: flex;
    align-items: center;

    > * {
      margin-right: 0.5rem;
    }
    .flag {
      width: 25px;
    }
  }

  #rrs-countrySelect-menu {
    min-width: 200px;
  }
}
