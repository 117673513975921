#gifts {
  > .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    .ibans {
      display: flex;
      flex-direction: column;

      > .iban-card {
        margin: 1rem;
      }
    }
  }

  .slider {
    .slide-content {
      padding: 0;
      display: flex;
      justify-content: center;

      img {
        display: block;
        width: 100vw;
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  #gifts {
    > .main {
      .ibans {
        flex-direction: row;
      }
    }

    .slider {
      .slide-content {
        padding: 0;
        display: flex;
        justify-content: center;

        img {
          display: block;
          width: 80vw;
        }
      }
    }
  }
}
