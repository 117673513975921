.map-container {
  width: 100%;
  height: 400px;
  border-radius: 20px;
  margin: 1rem;

  img {
    cursor: url(https://maps.gstatic.com/mapfiles/openhand_8_8.cur), default;
  }
}
