.Countdown {
  margin: 0 auto;
  padding-bottom: 20px;
  span {
    font-family: Thasadith;
    font-weight: normal;
  }
}

.Countdown-col {
  display: inline-block;
}

.Countdown-col-element {
  display: inline-block;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element strong {
  font-size: 2rem;
}

@media only screen and (min-width: 600px) {
  .Countdown-col-element strong {
    font-size: 3rem;
  }
}
