.scroll-to-top {
  position: fixed;
  bottom: 0;
  right: 0.5rem;
  z-index: 2;
  cursor: pointer;

  img {
    width: 50px;
  }
}
