.header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('./../../assets/images/pictures/tramonto.jpeg');
  background-repeat: no-repeat;
  background-position: 20% 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-size: cover;
  margin: 0 auto;
  color: white;
}

.header .Countdown .Countdown-col span {
  color: white;
}

#tagline {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  padding: 2rem 0;
  margin: 0 1rem;

  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
    color: var(--white);
  }

  .wedding-date {
    hr {
      margin: 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      font-family: 'Thasadith';
      color: #ffffff;
    }
  }
}

#tagline h1,
h3 {
  margin: 0.5rem;
}

/* vertical mobile display */
@media only screen and (min-width: 600px) {
  #tagline {
    h1 {
      font-size: 4rem;
    }

    h2 {
      font-size: 3rem;
    }
  }
}
