.end-section {
  > * {
    margin-bottom: 2rem;
  }
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
  }
}

@media only screen and (min-width: 800px) {
  .end-section {
    h3 {
      font-size: 3rem;
    }
  }
}
