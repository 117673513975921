@import url('https://fonts.googleapis.com/css2?family=Clicker+Script&display=swap');

@font-face {
  font-family: 'Thasadith';
  src: url(assets/fonts/thasadith/Thasadith-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Thasadith';
  src: url(assets/fonts/thasadith/Thasadith-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Calligraffiti';
  src: url(assets/fonts/calligraffiti/Calligraffitti-Regular.ttf)
    format('truetype');
}

html {
  font: normal 16px;
  color: var(--darkgrey);
  background-color: var(--white);
  --pink: rgb(237, 190, 173);
  --palepink: rgb(244, 223, 207);
  --darkgrey: rgb(94, 96, 97);
  --lightgreen: rgb(241, 242, 214);
  --olivegreen: #738860;
  --pearlgrey: rgb(235, 234, 233);
  --white: #ffffff;
  --black: #21241c;
  --secondary: #f19760;
}

input[type='text'] {
  font-size: 1rem;
}

p,
li,
span,
textarea,
label {
  font-family: 'Thasadith', sans-serif;
  color: var(--black);
  font-size: 1rem;
  font-weight: bold;
}

label {
  font-family: 'Thasadith', sans-serif;
  color: var(--black);
  font-size: 0.8rem;
  font-weight: bold;
}

h1 {
  font-family: 'Calligraffiti', cursive;
  font-size: 4rem;
}

h2 {
  font-family: 'Calligraffiti';
  color: var(--olivegreen);
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

h3 {
  font-family: 'Calligraffiti';
  color: var(--olivegreen);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

a {
  text-decoration: underline;
  color: var(--black);
  font-weight: bold;
}

a:hover {
  opacity: 1;
  color: var(--secondary);
  text-decoration: none;
}

.section {
  padding: 15px 0;
  box-sizing: border-box;
  margin-top: 1.5rem;
}

.section p {
  max-width: 50rem;
  margin: 1rem auto;
  padding: 10px 20px;
  text-align: center;
  line-height: 2;
}

.section h2 {
  text-transform: capitalize;
  text-align: center;
}

#loading,
#notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

#loading p,
#notfound p {
  font-size: 2rem;
  color: var(--olivegreen);
  padding: 1rem;
}

#loading i {
  color: var(--pink);
}

#notfound button {
  border-radius: 20px;
  padding: 15px 60px;
  margin-top: 2rem;
  border: 1px solid var(--darkgrey);
  font-size: 1em;
  letter-spacing: 2px;
  color: var(--darkgrey);
  background-color: var(--pearlgrey);
  cursor: pointer;
  outline: none;
}

#root {
  > div {
    > * {
      margin-bottom: 1rem;
    }

    & nav {
      margin-bottom: 0;
    }
  }
}

.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 3;
  }

  .ReactModal__Content {
    display: flex;
    flex-direction: column;
    > h2,
    span {
      margin-bottom: 1rem;
    }
  }
}
